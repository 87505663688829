<template>
  <div>
    <v-row>
      <v-col
        md="3"
        sm="6"
        cols="12"
        :class="$vuetify.breakpoint.xsOnly ? 'pt-5 pb-0' : ''"
      >
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          placeholder="Search name, email, or etc."
          prepend-inner-icon="mdi-magnify"
          color="primary"
        ></v-text-field>
      </v-col>
      <v-col
        md="3"
        sm="6"
        cols="12"
        :class="$vuetify.breakpoint.xsOnly ? 'py-0' : ''"
      >
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          max-width="290px"
          min-width="auto"
          offset-y
          nudge-top="20"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-text-field
              v-model="getStartDate"
              placeholder="Filter by Date"
              v-bind="attrs"
              outlined
              dense
              append-icon="mdi-calendar"
              v-on="on"
              clearable
              readonly
              @click:clear="filter_by_date = null"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            v-model="filter_by_date"
            no-title
            :max="current_date"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        md="3"
        sm="6"
        cols="12"
        :class="$vuetify.breakpoint.xsOnly ? 'py-0' : ''"
      >
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          append-icon="mdi-chevron-down"
          v-model="filter_by_reason"
          :items="reason_list"
          item-text="title"
          item-value="id"
          clearable
          outlined
          dense
          placeholder="Filter by Reason"
        />
      </v-col>
    </v-row>
    <div
      :class="
        $vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly ? 'pt-5' : ''
      "
    >
      <v-sheet elevation="3" class="rounded-b-0">
        <v-tabs
          height="125px"
          next-icon="mdi-chevron-right-circle"
          prev-icon="mdi-chevron-left-circle"
          v-model="selectedTab"
          show-arrows
        >
          <v-tab v-for="(item, id) in tabs" :key="id" class="tab-width">
            <div>
              <v-icon
                size="20"
                style="margin-top: -25px; font-size: 45px"
                :color="item.color"
                >mdi-circle-small</v-icon
              >

              <div class="tab-total" style="margin-top: -10px">
                {{ item.value }}
              </div>
              <div class="tab-name">
                {{ item.label }}
              </div>
            </div>
          </v-tab>
        </v-tabs>
      </v-sheet>
    </div>
    <v-divider></v-divider>
    <!-- TABLE -->
    <TatTable :activeTab="activeTab" @reload-count="getListCount" />
  </div>
</template>
<script>
import TatTable from "./tatTable.vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "tatManagement",
  data() {
    return {
      filter_by_date: null,
      search: "",
      menu1: false,
      current_date: this.$utils.getCurrentDate(new Date()),
      reason_list: [],
      selectedTab: 0,
      filter_by_reason: "",
      pending: 0,
      successful: 0,
      cancelled: 0,
    };
  },
  computed: {
    ...mapGetters({}),
    activeTab() {
      let tab = this.tabs[this.selectedTab].key;
      return tab;
    },
    getStartDate: {
      get() {
        return this.$utils.getDateFormat(this.filter_by_date);
      },
      set(value) {
        this.filter_by_date = value;
        return;
      },
    },
    tabs() {
      return [
        {
          id: 0,
          label: `Pending`,
          key: "pending",
          value: `${this.pending}`,
          color: "#1149B7",
        },
        {
          id: 1,
          label: `Approved`,
          key: "successful",
          value: `${this.successful}`,
          color: "#3DA922",
        },
        {
          id: 2,
          label: `Rejected`,
          key: "rejected",
          value: `${this.cancelled}`,
          color: "red",
        },
      ];
    },
    //  search: {
    //     get() {
    //         return this.getSearch;
    //     },
    //     set(value) {
    //         this.filter({search:value});
    //         // this.setSearch(value);
    //     },
    // },
  },
  watch: {
    filter_by_date() {
      this.setDate(this.filter_by_date);
      this.getListCount();
    },
    activeTab() {
      this.getListCount();
    },
    search() {
      this.setSearch(this.search);
      this.debounceGetCount();
    },
    filter_by_reason() {
      this.setReason(this.filter_by_reason);
      this.getListCount();
    },
  },
  mounted() {
    this.getListCount();
    this.getReasonList("internal_tat");
    this.getReasonList("client_tat");
  },
  created() {
    this.debounceGetCount = _.debounce(function () {
      this.getListCount();
    }, 800);
  },
  methods: {
    ...mapActions({
      setSearch: "tatManagement/setSearch",
      setDate: "tatManagement/setDate",
      setReason: "tatManagement/setReason",
    }),
    getReasonList(reason) {
      const onSuccess = (res) => {
        this.reason_list = [...res.data.data, ...this.reason_list];
      };

      const onFinally = () => {
        this.loading = false;
      };

      let params = {};
      params["reason_type"] = reason;

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
        onFinally,
      });
    },
    getListCount() {
      this.items = [];
      const onSuccess = (res) => {
        this.pending = res.data.data.pending;
        this.successful = res.data.data.successful;
        this.cancelled = res.data.data.rejected;
      };

      const onFinally = () => {
        this.loading = false;
      };

      let params = {};
      if (this.search) params["search"] = this.search;
      if (this.filter_by_date) params["date"] = this.filter_by_date;
      if (this.filter_by_reason) params["reason_id"] = this.filter_by_reason;

      return this.$request(
        this.$keys.GET,
        this.$urls.case.tatChangeRequest.count,
        {
          params,
          onSuccess,
          onFinally,
        }
      );
    },
  },
  components: { TatTable },
};
</script>
<style scoped>
.tabs {
  width: 200px;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}
.tab-total {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
}
.tab-name {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.active-class {
  background: #f2ffff;
}
</style>
