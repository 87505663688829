export default {
  pending: [
    {
      sortable: false,
      text: "Date",
      align: "center",
      value: "created",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Case Id",
      align: "center",
      value: "case_id",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Client Info",
      align: "center",
      value: "client",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "No. of days of extension",
      align: "center",
      value: "days_extension",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Reason of extension",
      align: "center",
      value: "tat_reason",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Status",
      align: "center",
      value: "status_display",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Action",
      align: "center",
      value: "action",
      class: ["table-header"],
    },
  ],

  approved: [
    {
      sortable: false,
      text: "Date",
      align: "center",
      value: "created",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Case Id",
      align: "center",
      value: "case_id",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Client Info",
      align: "center",
      value: "client",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "No. of days of extension",
      align: "center",
      value: "days_extension",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Reason of extension",
      align: "center",
      value: "tat_reason",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Status",
      align: "center",
      value: "status_display",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Action",
      align: "center",
      value: "action",
      class: ["table-header"],
    },
  ],

  rejected: [
    {
      sortable: false,
      text: "Date",
      align: "center",
      value: "created",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Case Id",
      align: "center",
      value: "case_id",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Client Info",
      align: "center",
      value: "client",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "No. of days of extension",
      align: "center",
      value: "days_extension",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Reason of extension",
      align: "center",
      value: "tat_reason",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Status",
      align: "center",
      value: "status_display",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Reject reason",
      align: "center",
      value: "reject_reason",
      class: ["table-header"],
    },
    {
      sortable: false,
      text: "Action",
      align: "center",
      value: "action",
      class: ["table-header"],
    },
  ],
};
